import React from 'react';
import AppContext from './AppContext';
import {Container} from 'react-bootstrap';
import TopMenu from './TopMenu';
import BottomMenu from './BottomMenu';
import Toasts from './Toasts';
import AppModal from './AppModal';
import OffcanvasMenu from './OffcanvasMenu';

class App extends React.Component {
    static contextType = AppContext;
    render() {
        return <Container fluid className="d-flex flex-column m-0 p-0"  style={{height:"100%"}}>
            <TopMenu />
            <div className='content flex d-flex flex-column flex-grow-1 flex-shrink-1 overflow-auto p-2 bg-light' ref={this.context.contentRef}>
                <Container className='p-2 bg-white flex-grow-1' style={{borderRadius: '10px', border: "solid black 1px"}}>
                {this.context.content}
                </Container>
            </div>
            <BottomMenu />
            <Toasts />
            <AppModal />
            <OffcanvasMenu />
        </Container>
    }
}

export default App;

import React from "react";
import AppContext from "../AppContext";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

class SenhaForm extends React.Component {

    static contextType = AppContext;

    state = {
        senha1: "",
        senha2: ""
    };

    submit(event) {
        event.preventDefault();

        if (this.state.senha1 !== this.state.senha2){
            alert("As senhas devem ser iguais!");
            return;
        }

        const method = "PUT";
        const url = `/usuario/${this.context.usuario.id}/senha`

        this.context.request(method, url, this.state)
            .then(() => {
                this.context.addToast({
                    header: "Sucesso",
                    body: "Alterações gravadas com sucesso!"
                });
                this.props.onExit();
            });

    }

    render() {
        return <Form onSubmit={event => this.submit(event)}>
            <Form.Group className="mt-2">
                <Form.Label>Nova senha</Form.Label>
                <Form.Control required type="password" value={this.state.senha1} onChange={e => this.setState({ senha1: e.target.value })} />
            </Form.Group>
            <Form.Group className="mt-2">
                <Form.Label>Repita a nova senha</Form.Label>
                <Form.Control required type="password" value={this.state.senha2} onChange={e => this.setState({ senha2: e.target.value })} />
            </Form.Group>
            <Form.Group className="mt-2 d-flex justify-content-between">
                <Button variant="secondary" onClick={() => this.props.onExit()}>
                    <FontAwesomeIcon icon={faTimes} /> Cancelar
                </Button>
                <Button type="submit" variant="primary">
                    <FontAwesomeIcon icon={faSave} /> Gravar
                </Button>
            </Form.Group>
        </Form>
    }
}

export default SenhaForm;
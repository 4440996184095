import React from "react";
import AppContext from "../AppContext";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

class UsuarioForm extends React.Component {

    static contextType = AppContext;

    state = {
        ...(this.props.usuario ?? {})
    };

    submit(event) {
        event.preventDefault();

        const id = this.state.id ?? '';

        const method = id ? "PUT" : "POST";
        const url = `/usuario/${id}`

        this.context.request(method, url, this.state)
            .then(() => {
                this.context.addToast({
                    header: "Sucesso",
                    body: "Alterações gravadas com sucesso!"
                });
                this.props.onSave();
            });

    }

    render() {

        const { nome, email, perfil, status } = this.state;

        return <Form onSubmit={event => this.submit(event)}>
            <Form.Group className="mt-2">
                <Form.Label>Nome</Form.Label>
                <Form.Control required value={nome} onChange={e => this.setState({ nome: e.target.value })} />
            </Form.Group>
            <Form.Group className="mt-2">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" value={email} onChange={e => this.setState({ email: e.target.value })} />
            </Form.Group>
            <Form.Group className="mt-2">
                <Form.Label>Status</Form.Label>
                <Form.Control required as="select" value={status} onChange={e => this.setState({ status: e.target.value })}>
                    <option value="">Selecione uma opção</option>
                    <option value="Ativo">Ativo</option>
                    <option value="Inativo">Inativo</option>
                </Form.Control>
            </Form.Group>
            <Form.Group className="mt-2">
                <Form.Label>Perfil</Form.Label>
                <Form.Control as="select" value={perfil} onChange={e => this.setState({ perfil: e.target.value })}>
                    <option value="">Selecione uma opção</option>
                    <option value="Administrador">Administrador</option>
                    <option value="Usuário">Usuário</option>
                </Form.Control>
            </Form.Group>
            <Form.Group className="mt-2 d-flex justify-content-between">
                <Button variant="secondary" onClick={() => this.context.closeModal()}>
                    <FontAwesomeIcon icon={faTimes} /> Cancelar
                </Button>
                <Button type="submit" variant="primary">
                    <FontAwesomeIcon icon={faSave} /> Gravar
                </Button>
            </Form.Group>
        </Form>
    }
}

export default UsuarioForm;
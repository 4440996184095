import React from 'react';
import AppContext from './AppContext';
import { Button, Image, Navbar } from 'react-bootstrap';
import logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

class TopMenu extends React.Component {
    static contextType = AppContext;
    render() {
        return <Navbar bg="dark">
            <Navbar.Brand onClick={() => this.context.home()} role="button">
                <Image src={logo} height={40} className='mx-3' />
            </Navbar.Brand>
            <Navbar.Text className="text-light">TJPA - Livro de Partes</Navbar.Text>
            {this.context.usuario?
            <Button variant='light' size="lg" className='ms-auto me-2' onClick={() => this.context.showMenu()}>
                <FontAwesomeIcon icon={faBars}/>
            </Button>:null}
        </Navbar>
    }   
}

export default TopMenu;
import { Component } from "react";
import AppContext from "./AppContext";
import { ListGroup, ListGroupItem, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faFileEdit, faList, faSignOut, faUsers } from "@fortawesome/free-solid-svg-icons";
import UsuarioList from "./usuario/UsuarioList";
import LivroList from "./livro/LivroList";
import LivroForm from "./livro/LivroForm";
import HomePage from "./HomePage";
import CategoriaList from "./categoria/CategoriaList";

class OffcanvasMenu extends Component {

    static contextType = AppContext;

    render() {
        return <Offcanvas className="menu" placement="end" show={this.context.menu} onHide={() => this.context.hideMenu()}>
            <Offcanvas.Header closeButton className="d-flex align-items-start">
                <h4 className="mb-2 text-center">TJPA - Livro de Partes</h4>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column mt-0 pt-0">
                <div className="text-center small d-flex flex-column align-items-center mb-3">
                    <div>{this.context.usuario?.nome} - {this.context.usuario?.perfil}</div>
                    <div>{this.context.usuario?.email}</div>
                </div>
                <ListGroup className="mb-3">      
                <ListGroupItem role="button" onClick={() => this.context.setContent(<LivroForm onSave={() => this.context.setContent(<HomePage />)} onCancel={() => this.context.setContent(<HomePage />)}  />)}>
                        <FontAwesomeIcon icon={faFileEdit} size="1x" />&nbsp;Registrar Livro
                    </ListGroupItem>  
                    <ListGroupItem role="button" onClick={() => this.context.setContent(<LivroList />)}>
                        <FontAwesomeIcon icon={faBook} size="1x" />&nbsp;Administrar Livros
                    </ListGroupItem>                
                    {this.context.usuario?.perfil === "Administrador"? <>
                    <ListGroupItem disabled={!this.context.alive} role="button" onClick={() => this.context.setContent(<UsuarioList />)}>
                        <FontAwesomeIcon icon={faUsers} size="1x" />&nbsp;Administrar Usuários
                    </ListGroupItem>                                        
                    <ListGroupItem disabled={!this.context.alive} role="button" onClick={() => this.context.setContent(<CategoriaList />)}>
                        <FontAwesomeIcon icon={faList} size="1x" />&nbsp;Administrar Unidades
                    </ListGroupItem>
                    </> : null}
                    <ListGroupItem className="text-danger" role="button" onClick={() => this.context.logoff()}>
                        <FontAwesomeIcon icon={faSignOut} size="1x" />&nbsp;Sair
                    </ListGroupItem>
                </ListGroup>
                <div className="text-center mt-auto">
                    TJPA ® 2023<br />Versão {process.env.REACT_APP_VERSION ?? '1.0.0'}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    }
}

export default OffcanvasMenu;
import React from "react";
import AppContext from "./AppContext";
import { faBook, faFileEdit, faKey, faList, faSignOut, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import UsuarioList from "./usuario/UsuarioList";
import LivroForm from "./livro/LivroForm";
import LivroList from "./livro/LivroList";
import CategoriaList from "./categoria/CategoriaList";
import SenhaForm from "./usuario/SenhaForm";

class HomePage extends React.Component {
    static contextType = AppContext;

    novaSenha() {
        this.context.openModal({
            title: "Alteração de Senha", body: <SenhaForm onExit={() => this.context.closeModal()} />
        });
    }
    render() {
        return <Row>
            <Col sm={4} className="text-center mb-4">
                <Button className="w-100 h-100 p-3" variant="outline-dark" disabled={!this.context.alive} onClick={() => this.context.setContent(<LivroForm onSave={() => this.context.setContent(<HomePage />)} onCancel={() => this.context.setContent(<HomePage />)} />)}>
                    <FontAwesomeIcon icon={faFileEdit} size="5x" /><br />
                    Registrar Livro
                </Button>
            </Col>
            <Col sm={4} className="text-center mb-4">
                <Button className="w-100 h-100 p-3" variant="outline-dark" disabled={!this.context.alive} onClick={() => this.context.setContent(<LivroList />)}>
                    <FontAwesomeIcon icon={faBook} size="5x" /><br />
                    Administrar Livros
                </Button>
            </Col>
            {this.context.usuario?.perfil === "Administrador" ?
                <>
                    <Col sm={4} className="text-center mb-4">
                        <Button className="w-100 h-100 p-3" variant="outline-dark" disabled={!this.context.alive} onClick={() => this.context.setContent(<UsuarioList />)}>
                            <FontAwesomeIcon icon={faUsers} size="5x" /><br />
                            Administrar Usuários
                        </Button>
                    </Col>
                    <Col sm={4} className="text-center mb-4">
                        <Button className="w-100 h-100 p-3" variant="outline-dark" disabled={!this.context.alive} onClick={() => this.context.setContent(<CategoriaList />)}>
                            <FontAwesomeIcon icon={faList} size="5x" /><br />
                            Administrar Unidades
                        </Button>
                    </Col>
                </> : null}
            <Col sm={4} className="text-center mb-4">
                <Button className="w-100 h-100 p-3" variant="outline-dark" disabled={!this.context.alive} onClick={() => this.novaSenha()}>
                    <FontAwesomeIcon icon={faKey} size="5x" /><br />
                    Alterar Senha
                </Button>
            </Col>
            <Col sm={4} className="text-center mb-4">
                <Button className="w-100 h-100 p-3" variant="danger" onClick={() => this.context.logoff()}>
                    <FontAwesomeIcon icon={faSignOut} size="5x" /><br />
                    Sair
                </Button>
            </Col>
        </Row>;
    }
}

export default HomePage;
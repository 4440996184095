import React from "react";
import AppContext from "../AppContext";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen, faPlus, faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';

class LivroForm extends React.Component {

    static contextType = AppContext;

    state = {
        CategoriaId: "",
        numero: "",
        data_registro: moment().format("YYYY-MM-DD"),
        Partes: [
		{titulo: "Recebimento do Serviço", texto:"No horário previsto, recebi o serviço do meu antecessor legal, o FULANO DE TAL, com todas as ordens em vigor."},
		{titulo: "Materiais", texto:""},
		{titulo: "Guarnições", texto:""},
		{titulo: "Juntada de Documentos", texto:""},
		{titulo: "Ocorrências", texto:""},
		{titulo: "Passagem do Serviço", texto:"No horário previsto, passei o serviço do meu sucessor legal, o FULANO DE TAL, com todas as ordens em vigor."},
	],

        categorias: [],
        activeKey: 0
    };

    componentDidMount() {
        this.context.request("GET", "/categoria/options").then(categorias => this.setState({ categorias }));
    }

    submit(event) {
        event.preventDefault();

        const errors = [];
        let i = 0;

        const fields = {
            data_registro: "Data de Registro",
            CategoriaId: "Unidade do Livro",
        };
        Object.keys(fields).forEach(key => !this.state[key] ? errors.push(<div key={i++}>{fields[key]} não pode ser vazio.</div>) : null);

        this.state.Partes.forEach((parte, j) => {
            const fields = {
                titulo: "Título",
                texto: "Texto",
            };
            Object.keys(fields).forEach(key => !parte[key] ? errors.push(<div key={i++}>{fields[key]} da Parte {j + 1} não pode ser vazio.</div>) : null);
        })

        if (errors.length) {
            this.context.addToast({ header: "Erro de Validação", body: errors });
            return;
        }

        this.context.request("POST", "/livro", {
            numero: this.state.numero,
            CategoriaId: this.state.CategoriaId,
            data_registro: this.state.data_registro,
            Partes: this.state.Partes
        }).then(() => {
            this.context.addToast({
                header: "Sucesso",
                body: "Alterações gravadas com sucesso!"
            });
            this.props.onSave();
        }).catch(error => {
            console.log(error);
            this.context.addToast({
                header: "Falha",
                body: "Houve uma falha na gravação dos dados: " + error.message
            });
        });

    }

    render() {

        const {
            data_registro,
            CategoriaId,
            Partes,
            categorias,
            activeKey
        } = this.state

        return <Form onSubmit={event => this.submit(event)} className="form-large">
            <h4 className="d-flex align-items-center">
                <FontAwesomeIcon icon={faFilePen} className="me-2" />Cadastro de Livro
                <div className="ms-auto text-nowrap">
                    <Button className="me-2" variant="secondary" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /><span className="d-none d-md-inline">&nbsp;Cancelar</span>
                    </Button>
                    <Button type="submit" variant="primary">
                        <FontAwesomeIcon icon={faSave} /><span className="d-none d-md-inline">&nbsp;Gravar Alterações</span>
                    </Button>
                </div>
            </h4>
            <hr />
            <h5>Dados Gerais</h5>
            <Row className="mt-3">
                <Col md={4}>
                    <Form.Group className="mb-2">
                        <Form.Label>Número</Form.Label>
                        <Form.Control value={this.state.numero} onChange={e => this.setState({numero: e.target.value})} onBlur={e => this.setState({numero: e.target.value.padStart(6,'0')})}/>
                    </Form.Group>
                </Col>
                <Col md={8}>
                    <Form.Group className="mb-2">
                        <Form.Label>Responsável</Form.Label>
                        <Form.Control value={this.context.usuario.nome} disabled />
                    </Form.Group>
                </Col>
                <Col md={8}>
                    <Form.Group className="mb-2">
                        <Form.Label className={CategoriaId ? "" : "label-required"}>Unidade</Form.Label>
                        <Form.Control as="select" value={CategoriaId.toString()} onChange={e => this.setState({ CategoriaId: parseInt(e.target.value) })}>
                            <option value="">Selecione uma Unidade</option>
                            {categorias.map((categoria, key) => <option key={key} value={categoria.id}>{categoria.nome}</option>)}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-2">
                        <Form.Label className={data_registro ? "" : "label-required"}>Data de Registro</Form.Label>
                        <Form.Control type="date" value={data_registro} onChange={e => this.setState({ data_registro: e.target.value })} />
                    </Form.Group>
                </Col>
            </Row>
            <h5 className="mt-3">Participação</h5>
            <Accordion activeKey={activeKey} onSelect={activeKey => this.setState({ activeKey })}>
                {Partes.map((parte, key) =>
                    <Accordion.Item key={key} eventKey={key}>
                        <Accordion.Header>{key + 1}. {parte.titulo}</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label className={parte.titulo ? "" : "label-required"}>Título</Form.Label>
                                        <Form.Control value={parte.titulo} onChange={e => this.setState(state => ({ Partes: state.Partes.map(p => p === parte ? { ...parte, titulo: e.target.value } : p) }))} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label className={parte.texto ? "" : "label-required"}>Texto</Form.Label>
                                        <Form.Control as="textarea" value={parte.texto} onChange={e => this.setState(state => ({ Partes: state.Partes.map(p => p === parte ? { ...parte, texto: e.target.value } : p) }))} />
                                    </Form.Group>
                                </Col>
                                <Col md={12} className="d-flex justify-content-end">
                                    <Button variant="danger" onClick={() => this.setState(state => ({ Partes: state.Partes.filter(p => p !== parte), activeKey: state.Partes.length }))}>
                                        <FontAwesomeIcon icon={faTrash} /> Excluir Parte
                                    </Button>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>)}
            </Accordion>
            <div className="mt-2 d-flex flex-row justify-content-between">
                <Button className="me-2 align-self-start" variant="success" onClick={() => this.setState(state => ({ Partes: [...state.Partes, {}], activeKey: state.Partes.length }))}>
                    <FontAwesomeIcon icon={faPlus} /> Adicionar Parte
                </Button>
                <div>
                    <Button className="me-2" variant="secondary" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /><span className="d-none d-md-inline">&nbsp;Cancelar</span>
                    </Button>
                    <Button type="submit" variant="primary">
                        <FontAwesomeIcon icon={faSave} /><span className="d-none d-md-inline">&nbsp;Gravar Alterações</span>
                    </Button>
                </div>
            </div>
        </Form >
    }
}

export default LivroForm;

import React from "react";
import Datatable from "../Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faFastBackward } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../AppContext";
import { Button } from "react-bootstrap";
import LivroDespachoForm from "./LivroDespachoForm";

class LivroList extends React.Component {

    static contextType = AppContext;

    myCallback() {
        this.context.setContent(<LivroList key={new Date().getUTCMilliseconds()} />);
    }

    onAction(action, callback) {
        switch (action.title) {
            case "Despachar":
                this.context.request("GET", `/livro/${action.id}`).then(livro => {
                    this.context.setContent(<LivroDespachoForm livro={livro} onCancel={() => this.myCallback()} onSave={() => this.myCallback()} />);
                });
                break;
            case "Imprimir":
                    this.context.request("GET", `/livro/print/${action.id}`, null, true)
                        .then(response => response.blob())
                        .then(blob => {                            
                            window.open(URL.createObjectURL(blob));
                        })
                        .catch(error => {
                            this.context.addToast({ header: "Error", body: "Falha na geração do arquivo PDF! " + error.message });

                        });
                    break;                
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o item com Nº " + action.id + "?")) {
                    this.context.request("DELETE", `/livro/${action.id}`).then(() => {
                        this.context.addToast({ header: "Sucesso", body: "Item excluído com sucesso!" });
                        callback();
                    });
                }
                break;
            default:
                break;
        }
    }

    render() {
        return <>
            <h3 className="d-flex justify-content-between">
                <div className="text-nowrap">
                    <FontAwesomeIcon icon={faBookOpen} />&nbsp;Livros de Partes
                </div>
                <div className="text-nowrap">
                    <Button variant="secondary" className="mx-1 text-nowrap" onClick={() => this.context.home()}>
                        <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline">&nbsp;Voltar</span>
                    </Button>
                </div>
            </h3>
            <hr />
            <Datatable
                endpoint="/livro"
                onAction={(action, callback) => this.onAction(action, callback)}
            />
        </>
    }
}

export default LivroList;
import React from "react";
import Datatable from "../Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faList } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../AppContext";
import CategoriaForm from "./CategoriaForm";
import { Button } from "react-bootstrap";

class CategoriaList extends React.Component {

    static contextType = AppContext;

    myCallback(callback) {
        this.context.closeModal();
        callback();
    }

    onAction(action, callback) {
        switch (action.title) {
            case "Editar":
                this.context.request("GET", `/categoria/${action.id}`).then(categoria => {
                    this.context.openModal({
                        title: "Editar Unidade", body: <CategoriaForm categoria={categoria} onSave={() => this.myCallback(callback)} />
                    });
                });
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir a Unidade com ID " + action.id + "?")) {
                    this.context.request("DELETE", `/categoria/${action.id}`).then(() => {
                        this.context.addToast({ header: "Sucesso", body: "Unidade excluída com sucesso!" });
                        callback();
                    });
                }
                break;
            default:
                break;
        }
    }

    onAdd(callback) {
        this.context.openModal({
            title: "Nova Unidade", body: <CategoriaForm onSave={() => this.myCallback(callback)} />
        });
    }

    render() {
        return <>
            <h3 className="d-flex align-items-center">
                <FontAwesomeIcon icon={faList} className="mr-2" />&nbsp;Unidades
                <Button variant="secondary" className="ms-auto text-nowrap" onClick={() => this.context.home()}>
                    <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline">&nbsp;Voltar</span>
                </Button>
            </h3>
            <hr />
            <Datatable
                endpoint="/categoria"
                onAction={(action, callback) => this.onAction(action, callback)}
                onAdd={callback => this.onAdd(callback)}
            />
        </>
    }
}

export default CategoriaList;